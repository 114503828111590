<template>
  <TosListVuetify :entityName="entityName" :headers="headers">
    <template #customFilters="slotProps">
      <v-container fluid>
        <v-expansion-panels>
          <v-expansion-panel class="filters">
          <v-expansion-panel-header>
            <v-row>
              <v-col cols="2">
                <v-text-field :label="tLabel('targa')" v-model="slotProps.data.targa" @click.native.stop clearable/>
              </v-col>
              <v-col cols="2">
                <v-select :label="tLabel('Posizione')" v-model="slotProps.data.filtroPosizione" :items="posizione" @click.native.stop clearable/>
              </v-col>
              <v-col cols="2">
                <v-select :label="tLabel('Danneggiato')" v-model="slotProps.data.filtroDanneggiato" :items="danneggiato" @click.native.stop clearable/>
              </v-col>
              <v-col cols="2">
                <v-select :label="tLabel('Parco')" :items="listParchi" v-model="slotProps.data.filtroParco" @click.native.stop
                  item-text="label" item-value="id" @input="(a) => { parcoBinariId = a }" clearable/>
              </v-col>
              <v-col cols="2">
                <v-select :label="tLabel('Binario')" :items="listBinari" v-model="slotProps.data.filtroBinario" @click.native.stop
                  item-text="numero" item-value="id"  :disabled="slotProps.data.filtroParco == null" clearable/>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="2">
                <v-text-field :label="tLabel('Viaggio In')" v-model="slotProps.data.filtroViaggioIn" @click.native.stop clearable/>
              </v-col>
              <v-col cols="2">
                <v-text-field :label="tLabel('Viaggio Out')" v-model="slotProps.data.filtroViaggioOut" @click.native.stop clearable/>
              </v-col>
              <v-col cols="2">
                <v-text-field :label="tLabel('Vettore In')" v-model="slotProps.data.filtroVettoreIn" @click.native.stop clearable/>
              </v-col>
              <v-col cols="2">
                <v-text-field :label="tLabel('Vettore Out')" v-model="slotProps.data.filtroVettoreOut" @click.native.stop clearable/>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
      <v-dialog v-model="showDialog" max-width="400" scrollable>
        <v-card>
          <v-card-title>Dettagli</v-card-title>
          <v-card-text>
            <TosViewItemVuetify :item="dialogContent" />
          </v-card-text>
          <v-card-actions  style="padding-bottom: 15px;">
            <v-spacer />
            <v-btn color="primary" @click="goToAssociaDanni(dialogContent.id)">Modifica</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <template v-slot:item.actions>
    </template>

    <template #item.danni="slotProps">
      <a href="#" v-if="!!slotProps.value" @click.prevent="showInfo(slotProps)">
        <v-icon color="warning">mdi-alert-outline</v-icon> 
      </a>
      <a href="#" v-else @click.prevent="goToAssociaDanni(slotProps.data.id)">
        <v-icon color="success" class="add-damage">mdi-plus-box</v-icon> 
      </a>
    </template>
  </TosListVuetify>
</template>

<script>
import TosListVuetify from "../../components/anagrafiche/TosListVuetify";
import TosViewItemVuetify from "../../components/anagrafiche/TosViewItemVuetify";
import RailTosService from "../../service/tos/RailTosService";

export default {
  name: "VisiteCarri",
  data() {
    return {
      entityName: "visiteCarri",
      posizione: [
        { text: "PREAVVISATO", value: "PREAVVISATO" },
        { text: "IN TERMINAL", value: "IN TERMINAL" },
        { text: "USCITO", value: "USCITO" },
      ],
      danneggiato: [
        { text: "SI", value: "SI" },
        { text: "NO", value: "NO" },
      ],
      headers: [
        { text: "targa", value: "targa" },
        { text: "danni", value: "danni", tooltip: "true"},
        { text: "gateIn", value: "gateIn" },
        { text: "gateOut", value: "gateOut" },
        { text: "viaggio In", value: "viaggioIn" },
        { text: "viaggio Out", value: "viaggioOut" },
        { text: "convoglio", value: "convoglioNome" },
        { text: "parco", sortable: false, value: "parco" },
        { text: "binario", sortable: false, value: "binario" },
        { text: "vettore In", value: "vettoreIn" },
        { text: "Carro", value: "carro", link: "true" },
        { text: "Tipo Carro", value: "tipoCarro", link: "true" },
      ],
      listParchi: [],
      listBinari: [],
      parcoBinariId: null,
      showDialog: false,
      dialogContent: {
        damages: null
      }
    };
  },
  watch: {
    parcoBinariId() {
      this.reloadBinari();
    }
  },
  async mounted() {
    this.listParchi = (await this.$api.get(this.$apiConfiguration.BASE_PATH + "parcoBinari/shortList")).data;
    this.reloadBinari();
  },
  methods: {
    showInfo(obj) {
      this.dialogContent = {
        "id": obj.data.id,
        "label": obj.data.danni,
        "type": "logDanni/visitaCarro"
      };
      this.showDialog = true;
    },
    goToAssociaDanni(idVisita) {
      const service = new RailTosService("");
      const el = {
        id: idVisita,
        type: "carriConvoglio/visitaCarro"
      }

      service.getInfo(el).then(data => 
        this.$router.push({
          path: "/callTreno/ConvoyAssociaDanni/" + data + "/" + null + "/" + "CARRO",
        })
      );
    },
    async reloadBinari() {
      const listBinariResp = await this.$api.get(this.$apiConfiguration.BASE_PATH + "binari/list", { params: { filters: { parcoBinari_id___EQ: parseInt(this.parcoBinariId) } } });
      this.listBinari = listBinariResp.data;
    },
  },
  components: {
    TosListVuetify: TosListVuetify,
    TosViewItemVuetify: TosViewItemVuetify
  },
};
</script>

<style>
  .filters::before {
    box-shadow: none;
  }
  .v-expansion-panel-header {
    pointer-events: none;
  }
  .v-expansion-panel-header__icon {
    pointer-events: All;
  }
  .v-expansion-panel-header .v-input {
    pointer-events: All;
  }
  .add-damage {
    opacity: .3!important;
  }
  .add-damage:hover {
    opacity: 1!important;
  }
</style>
